<template>
  <v-form style="position: relative">
    <v-container fluid>
      <v-row>
        <v-col cols="6">
          <CustomerSearch :on-selected="onCustomerAcUpdated" />
          <v-textarea
            :error-messages="addressTextErrors"
            :label="$t('addressText')"
            @blur="$v.item.addressText.$touch()"
            @input="$v.item.addressText.$touch()"
            v-model="item.addressText"
            outlined
          />
        </v-col>
        <v-col cols="6">
          <InputDate
            :error-messages="dateErrors"
            :label="$t('invoiceDate')"
            required
            v-model="item.date"
            @blur="$v.item.date.$touch()"
            @input="$v.item.date.$touch()"
          />
          <v-alert :type="item['@id'] ? 'success' : 'info'" outlined text class="mt-8 ml-9">
            <template v-if="item['@id']"> {{ $t(item.type) }}-Nr.: {{ item.number }}</template>
            <template v-else>
              {{ numberInfo }}
            </template>
          </v-alert>
          <v-select
            v-if="isInvoice"
            :error-messages="paymentMethodErrors"
            @blur="$v.item.paymentMethod.$touch()"
            @input="$v.item.paymentMethod.$touch()"
            :label="$t('PaymentMethod')"
            :items="paymentMethods"
            v-model="item.paymentMethod"
            item-value="@id"
            class="mt-8 ml-9"
          >
            <template slot="selection" slot-scope="data">
              {{ data.item.name }}
            </template>
            <template slot="item" slot-scope="data">
              <v-list-item-content>
                <v-list-item-title v-html="`${data.item.name}`" />
              </v-list-item-content>
            </template>
          </v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="10">
          <h4>{{ $t('headerText') }}</h4>
          <TextTemplateSelector @textTemplateSelected="templateSelected('headerText', ...arguments)" />
          <InputEditor v-model="item.headerText" :error-messages="headerTextErrors" :required="true" />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="10">
          <template v-if="item['@id']">
            <PositionListAndAdd
              :default-item-props="{ invoice: this.item['@id'] }"
              :positions="this.item.positions"
              object-type="invoice"
              :handle-message="handleMessage"
              :update-parent="this.updateInvoice"
              :show-title="false"
              @updatePositions="updatePositionHandler"
            />
          </template>
          <template v-else>
            <v-alert text type="info"> {{ saveFirstInfo }}</v-alert>
          </template>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="10">
          <h4>{{ $t('footerText') }}</h4>
          <TextTemplateSelector @textTemplateSelected="templateSelected('footerText', ...arguments)" />
          <InputEditor v-model="item.footerText" :error-messages="footerTextErrors" :required="true" />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12"></v-col>
      </v-row>
    </v-container>
    <v-overlay :value="isFrozen" :absolute="true"></v-overlay>
  </v-form>
</template>

<script>
import has from 'lodash/has';
import { validationMixin } from 'vuelidate';
import { required, requiredIf } from 'vuelidate/lib/validators';
import CustomerMixin from '@/mixins/CustomerMixin';
import ProfileMixin from '@/mixins/ProfileMixin';
import AddressMixin from '@/mixins/AddressMixin';
import TextTemplateMixin from '@/mixins/TextTemplateMixin';
import { date } from '@/validators/date';
import InputDate from '@/components/InputDate';
import PositionMixin from '@/mixins/PositionMixin';
import PositionListAndAdd from '@/components/position/ListAndAdd';
import InvoiceMixin from '@/mixins/InvoiceMixin';
import TenantMixin from '@/mixins/TenantMixin';
import { mapActions } from 'vuex';
import InputEditor from '@/components/InputEditor';
import DialogMixin from '@/mixins/DialogMixin';
import NotificationMixin from '../../mixins/NotificationMixin';
import TextTemplateSelector from '@/components/textTemplate/Selector';
import CustomerSearch from '@/components/customer/Search.vue';

export default {
  name: 'InvoiceForm',
  mixins: [
    validationMixin,
    CustomerMixin,
    ProfileMixin,
    AddressMixin,
    PositionMixin,
    InvoiceMixin,
    TenantMixin,
    DialogMixin,
    NotificationMixin,
    TextTemplateMixin
  ],
  components: {
    CustomerSearch,
    InputDate,
    PositionListAndAdd,
    InputEditor,
    TextTemplateSelector
  },
  props: {
    values: {
      type: Object,
      required: true
    },
    errors: {
      type: Object,
      default: () => {}
    },
    initialValues: {
      type: Object,
      default: () => {}
    },
    handleMessage: {
      type: Function,
      required: true
    }
  },
  mounted() {
    // this.fetchAllCustomers({ pagination: false });
    this.applyDefaultHeaderText();
    this.applyDefaultFooterText();
    this.paymentMethodGetSelectedItems();
  },
  data() {
    return {
      currentCustomer: null,
      invoiceTypes: [
        { text: this.$t('invoice'), value: 'invoice' },
        { text: this.$t('cancellation'), value: 'cancellation' },
        { text: this.$t('credit'), value: 'credit' }
      ]
    };
  },
  computed: {
    isInvoice() {
      return this.$route.meta.type === 'invoice';
    },
    pageTitle() {
      return this.isInvoice ? this.$t('Invoices') : this.$t('Credits');
    },
    numberInfo() {
      return this.isInvoice ? this.$t('autoInvoiceNumber') : this.$t('autoCreditNumber');
    },
    saveFirstInfo() {
      return this.isInvoice ? this.$t('saveInvoiceFirst') : this.$t('saveCreditFirst');
    },
    defaultHeaderTextTemplate() {
      if (!this.item.headerText && this.currentAccountSettings['defaultInvoiceHeaderText']) {
        return this.resolveTextTemplate(this.currentAccountSettings['defaultInvoiceHeaderText']);
      }
      return null;
    },
    defaultFooterTextTemplate() {
      if (!this.item.footerText && this.currentAccountSettings['defaultInvoiceFooterText']) {
        return this.resolveTextTemplate(this.currentAccountSettings['defaultInvoiceFooterText']);
      }
      return null;
    },
    positions() {
      if (this.item.positions) {
        return this.resolvePositions(this.item.positions);
      }
      return [];
    },
    itemCustomer() {
      return this.item.customer;
    },
    isFrozen() {
      return !!(this.item && this.item.frozen);
    },
    paymentMethods() {
      if (this.getAccountSettings() === null) {
        return [];
      }
      return this.getAccountSettings().paymentMethods;
    },
    // eslint-disable-next-line
    item() {
      return this.initialValues || this.values;
    },
    // region validation
    addressTextErrors() {
      const errors = [];

      if (!this.$v.item.addressText.$dirty) {
        return errors;
      }

      has(this.violations, 'addressText') && errors.push(this.violations.addressText);

      !this.$v.item.addressText.required && errors.push(this.$t('Field is required'));

      return errors;
    },
    headerTextErrors() {
      const errors = [];

      if (!this.$v.item.headerText.$dirty) {
        return errors;
      }
      has(this.violations, 'headerText') && errors.push(this.violations.headerText);
      !this.$v.item.headerText.required && errors.push(this.$t('Field is required'));

      return errors;
    },
    footerTextErrors() {
      const errors = [];

      if (!this.$v.item.footerText.$dirty) {
        return errors;
      }
      has(this.violations, 'footerText') && errors.push(this.violations.footerText);
      !this.$v.item.footerText.required && errors.push(this.$t('Field is required'));

      return errors;
    },
    dateErrors() {
      const errors = [];
      if (!this.$v.item.date.$dirty) {
        return errors;
      }

      has(this.violations, 'date') && errors.push(this.violations.date);

      !this.$v.item.date.required && errors.push(this.$t('Field is required'));

      return errors;
    },
    enabledErrors() {
      const errors = [];

      if (!this.$v.item.enabled.$dirty) {
        return errors;
      }

      has(this.violations, 'enabled') && errors.push(this.violations.enabled);

      return errors;
    },
    lockedErrors() {
      const errors = [];

      if (!this.$v.item.locked.$dirty) {
        return errors;
      }

      has(this.violations, 'locked') && errors.push(this.violations.locked);

      return errors;
    },
    typeErrors() {
      const errors = [];

      if (!this.$v.item.type.$dirty) {
        return errors;
      }

      has(this.violations, 'type') && errors.push(this.violations.type);

      return errors;
    },
    discountErrors() {
      const errors = [];

      if (!this.$v.item.discount.$dirty) {
        return errors;
      }

      has(this.violations, 'discount') && errors.push(this.violations.discount);

      return errors;
    },
    paymentMethodErrors() {
      const errors = [];

      if (!this.$v.item.paymentMethod.$dirty) {
        return errors;
      }

      has(this.violations, 'paymentMethod') && errors.push(this.violations.paymentMethod);
      !this.$v.item.paymentMethod.required && errors.push(this.$t('Field is required'));

      return errors;
    },
    violations() {
      return this.errors || {};
    }
    //endregion
  },
  methods: {
    log(message) {
      console.log(message);
    },
    ...mapActions({
      paymentMethodGetSelectedItems: 'paymentMethod/fetchSelectItems'
    }),
    applyAddress(profile = null, address = null) {
      let newAddressText = '';
      if (address === null || profile == null) {
        newAddressText = '';
      } else {
        if (profile.company) {
          newAddressText = profile.company + '\nz.H ';
        }
        if (profile.title) {
          newAddressText += profile.title + ' ' + '\n';
        }
        newAddressText += profile.firstName + ' ' + profile.lastName + '\n';
        newAddressText += address.streetNr + '\n' + address.zipCode + ' ' + address.city;
      }

      this.$set(this.item, 'addressText', newAddressText);
    },
    templateSelected(target, textTemplate) {
      if (textTemplate && target) {
        this.$set(this.item, target, textTemplate);
      }
    },
    onCustomerAcUpdated(selectedCustomer) {
      if (selectedCustomer) {
        this.currentCustomer = selectedCustomer;
        this.item.customer = selectedCustomer['@id'];
      } else {
        this.currentCustomer = null;
        this.item.customer = null;
      }
    },
    updatePositionHandler(updatedPositions) {
      this.item.positions = updatedPositions;
    },
    applyDefaultHeaderText() {
      if (this.defaultHeaderTextTemplate && !this.item.headerText) {
        this.templateSelected('headerText', this.defaultHeaderTextTemplate.content);
      }
    },
    applyDefaultFooterText() {
      if (this.defaultFooterTextTemplate && !this.item.footerText) {
        this.templateSelected('footerText', this.defaultFooterTextTemplate.content);
      }
    }
  },
  watch: {
    currentCustomer(newVal, oldVal) {
      if (
        this.currentCustomer &&
        oldVal === null &&
        !(
          this.item.addressText === null ||
          typeof this.item.addressText === 'undefined' ||
          this.item.addressText.length === 0
        )
      ) {
        return;
      }
      if (this.currentCustomer) {
        let profileId = this.currentCustomer.profile;
        if (typeof profileId === 'object') {
          profileId = profileId['@id'];
        }
        this.resolveProfileAsync(profileId).then((profile) => {
          this.resolveAddressesAsync(profile.addresses).then((addresses) => {
            let pickedAddress = null;
            if (addresses) {
              addresses.forEach((address) => {
                if (pickedAddress === null) {
                  pickedAddress = address;
                }
                if (address.type === 'invoice') {
                  pickedAddress = address;
                }
              });
            }
            this.item.customer = this.currentCustomer['@id'];
            this.applyAddress(profile, pickedAddress);
          });
        });
      }
      if (!newVal && oldVal) {
        this.item.customer = null;
        this.applyAddress();
      }
    },
    itemCustomer(currentItemCustomer) {
      if (currentItemCustomer) {
        this.currentCustomer = this.resolveCustomer(currentItemCustomer);
      }
    },
    defaultHeaderTextTemplate() {
      this.applyDefaultHeaderText();
    },
    defaultFooterTextTemplate() {
      this.applyDefaultFooterText();
    }
  },
  validations: {
    item: {
      date: {
        required,
        date
      },
      addressText: {
        required
      },
      headerText: {
        required
      },
      footerText: {
        required
      },
      type: {
        required
      },
      paymentMethod: {
        required: requiredIf(function () {
          return this.isInvoice;
        })
      },
      discount: {}
    }
  },
  beforeUpdate() {
    let invoiceType = this.$props.values.type;

    if (this.isInvoice) {
      let invoiceTypes = {
        invoice: { text: this.$t('invoice'), value: 'invoice' },
        cancellation: { text: this.$t('cancellation'), value: 'cancellation' }
      };

      this.invoiceTypes = [invoiceType === 'invoice' ? invoiceTypes['invoice'] : invoiceTypes['cancellation']];

      this.item.type = this.item.type || 'invoice';
    } else {
      this.invoiceTypes = [{ text: this.$t('credit'), value: 'credit' }];
      this.item.type = 'credit';
    }
  }
};
</script>
