import {mapActions, mapGetters} from "vuex";
import {mapFields} from "vuex-map-fields";
import remove from "lodash/remove";

export default {
  data() {
    return {
      loadingDownload: false,
      loadingDownloadLock: false,
      loadingInvoices: [],
    };
  },
  computed: {
    ...mapFields("invoice", {
      invoiceIds: "allIds",
      invoiceIdsCached: "cacheAllIds",
      invoiceHandled: 'handled',
      invoiceActionHandled: 'handleAction'
    }),
    ...mapGetters("invoice", {
      invoiceItems: 'list',
      findInvoice: "find",
    }),
  },
  methods: {
    ...mapActions("invoice", {
      retrieveInvoice: "load",
      fetchAllInvoices: 'fetchAll',
      updateInvoice: "update",
      invoiceActions: "action",
    }),
    resolveInvoice(itemId) {
      if (!this.invoiceIdsCached.includes(itemId) && !this.loadingInvoices.includes(itemId)) {
        this.loadingInvoices.push(itemId);
        this.retrieveInvoice(decodeURIComponent(itemId));
      }
      return this.findInvoice(itemId);
    },
    resolveInvoices(itemIds) {
      if (typeof itemIds === "undefined") {
        return [];
      }
      return itemIds.map((itemId) => {
        return this.resolveInvoice(itemId);
      }).filter(e => e);
    },
    getInvoiceItem(invoiceIri, type) {
      if (invoiceIri !== null) {
        let invoice = this.resolveInvoice(invoiceIri);
        if (invoice) {
          return invoice[type];
        }
      }
      return '';
    },
    removeInvoiceRelated(item, type, relatedIri) {
      item[type] = remove(item[type], function (n) {
        return !(n === relatedIri);
      });
    },
    generateInvoiceDocument(invoice) {
      this.invoiceActions({item: invoice, action: 'generate'});
    },
    regenerateInvoiceDocument(invoice) {
      this.invoiceActions({item: invoice, action: 'regenerate'});
    },
    async freezeDocument(invoice) {
      let item = {
        ["@id"]: invoice["@id"],
        frozen: true
      };
      return this.updateInvoice(item);
    },
    setPaidDocument(invoice) {
      let item = {
        ["@id"]: invoice["@id"],
        paid: true
      };
      this.updateInvoice(item);
    }
  }
};
